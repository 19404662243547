import { clsx } from "clsx";

import type { PolymorphicAsProps } from "../types";

import type { ElementType } from "react";


const styles = {
  inherited: [
    "[&_a]:text-action [&_a]:underline [&_a]:decoration-action/50 [&_a:hover]:decoration-action/100",
  ],
  as: {
    p: ["text-base/relaxed"],
    h1: ["text-4xl/normal font-bold"],
    h2: ["text-2xl/normal font-semibold"],
    h3: ["text-xl/snug font-semibold"],
    h4: ["text-lg/tight font-medium"],
    h5: ["text-base/tight font-medium"],
    small: ["text-xs/tight"],
  },
};

const defaultElement = "p";

export type TypographyProps<E extends ElementType = typeof defaultElement> =
  PolymorphicAsProps<E> & {
    fg?: "primary" | "secondary" | "tertiary" | "quaternary";
  };

export function Text<E extends React.ElementType = "p">({
  className,
  as,
  fg = "primary",
  ...props
}: TypographyProps<E>) {
  const Component = as ?? defaultElement;

  // can't use template literals for tailwind styles
  let textColor;
  switch (fg) {
    case "secondary":
      textColor = "text-fg-secondary dark:text-fg-secondary-dark";
      break;
    case "tertiary":
      textColor = "text-fg-tertiary dark:text-fg-tertiary-dark";
      break;
    case "quaternary":
      textColor = "text-fg-quaternary dark:text-fg-quaternary-dark";
      break;
    case "primary":
    default:
      textColor = "text-fg-primary dark:text-fg-primary-dark";
      break;
  }

  return (
    <Component
      {...props}
      data-slot="text"
      className={clsx(
        className,
        styles.as[as as keyof typeof styles.as] ?? styles.as.p,
        styles.inherited,
        textColor
      )}
    />
  );
}
